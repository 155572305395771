.home-margin{
    margin: 20px;
}

.app__header_section__padding {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 10px 0px 20px;
}

.app__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    max-width: 700px;
    margin-right: 20px;
    font-family: var(--font-family);
}

.app__header_section__padding h1 {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 22px;
    margin-top: 50px;
}

.app__header-content p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    text-align: justify;
    color: var(--color-text);
    margin-top: 1.5rem;
}

.app__header-content__input {
    width: 100%;
    margin: 2rem 0 1rem;
    display: flex;
}

.app__header-content__input input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-size: 20px;
    line-height: 27px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.app__header-content__input button {
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    background: #ff4820;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: #fff;
    cursor: pointer;
    outline: none;
    padding: 0 1rem;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.app__header-image {
    display: block;
    margin-right: 10px;
    max-width: 50%;
    height: auto;
    margin-top: 20px;
}

.app__header-image img {
    width: 100%;
    height: auto;
    padding-right: 20px;
}

/* Media Query for Small Screens */
@media screen and (max-width: 768px) {
    .app__header-content {
        margin-right: 0; /* Remove right margin on small screens */
    }

    .app__header-image {
        max-width: 100%; /* Set max-width to 100% on small screens */
        margin-top: 10px; /* Adjust margin for better spacing */
    }

    .app__header_section__padding h1{
        font-size: 16px;
    }

    .app__header-content p{
        font-size: 13px;
    }

    .app__header-content__input button, .app__header-content__input input{
        font-size: 13px;
    }

}


.d-block_w-100{
    width: 100%;
    height: auto;

}





.Home_Card-heading h1{
    display: flex;
    justify-content: center;
    font-weight: 500;
    text-transform: capitalize;
    margin-top: 100px;
    font-family: var(--font-family);
  }

  .Home_Card-heading p{
    display: flex;
    justify-content: center;
    margin: 20px 20px 0px 20px;
    font-weight: 400;
    font-size: 20px;
    font-family: var(--font-family);

  }


  .Home_block-margin{
    margin-top: 100px;
  }


.carouselcaption{
    font-family: var(--font-family);
}



/* home card our approach and why it matters*/

.home_split{
    margin-top: 100px;
}

.split-screen {
    display: flex;
    height: 100vh;
    width: auto;
    margin: 60px 30px 0 60px;
  }
  
  .split-left, .split-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 60vh;
  }
  
  .split-left {
    width: 100%;
    height: auto;
    background-color: #3498db;
    color: #fff;
  }
  
  .split-right {
    width: 100%;
    height: auto;
    background-color: #f1f1f1;
    padding: 2.8rem;
  }

  .split-left h1, .split-right h1{
    font-family: var(--font-family);
    text-align: center;
  }

  .split-left p, .split-right p{
    text-align: center;
    font-family: var(--font-family);
  }


  @media (max-width: 768px) {
    .split-screen {
      flex-direction: column;
    }
  
    .split-left, .split-right {
      flex: initial;
    }

    .split-left h1, .split-right h1{
        font-size: 17px;
    }

    .split-left p, .split-right p{
        font-size: 13px;
    }

    .Home_Card-heading h1{
        font-size: 17px;
    }

    .Home_Card-heading p{
        font-size: 13px;
    }
  }

  @media (max-width: 450px) {
    .carouselcaption{
        font-size: 13px;
    }

  }

  
