
  
  /* Navigation links */
  .navbar a {
    float: left;
    font-size: 16px;
    color: black;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-weight: 600;
    font-family: var(--font-family);
  }
  
  /* The subnavigation menu */
  .subnav {
    float: left;
    overflow: hidden;
  }
  
  /* Subnav button */
  .subnav .subnavbtn {
    font-size: 16px;
    border: none;
    outline: none;
    color: #000;
    padding: 14px 16px;
    background-color: inherit;
    font-family: var(--font-family);
    margin: 0;
    font-weight: 600;
  }
  
  /* Add a red background color to navigation links on hover */
  
  /* Style the subnav content - positioned absolute */
  .subnav-content1{
    display: none;
    background: white;
    position: absolute;
    left:79px;
    width: 100%;
    z-index: 1;
    width: 400px; 
    overflow-wrap: break-word; 
    word-wrap: break-word; 
    word-break: break-word; 
  }
 
  .subnav-content2{
    display: none;
    background: white;
    position: absolute;
    left:226px;
    width: 100%;
    z-index: 1;
  }
 
  
  /* Style the subnav links */
  .subnav-content1 a, .subnav-content2 a{
    float: left;
    color: #000;
    text-decoration: none;
    font-family: var(--font-family);
  }
  
  /* Add a grey background color on hover */
  .subnav-content1 a:hover, .subnav-content2 a:hover {
    background-color: #eee;
    color: black;
  }
  
  /* When you move the mouse over the subnav container, open the subnav content */
  .subnav:hover .subnav-content1, .subnav:hover .subnav-content2 {
    display: block;
  }













.app_navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    padding-right: 0px;
}

.app__navbar-links{
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.app__navbar-links_logo{
    display: flex;
    align-items: center;
    margin-right: 2rem;
    float: left;
    padding-right: 18px;
    padding-left: 0px;
    margin-left: 0px;
}
.app__navbar-links_logo img{
    width: 60px;
    height: auto;
}

.app__navbar-links_logo p{
    color: rgb(133, 4, 4);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    text-transform: capitalize;
    margin: 0 1rem;
}


.app__navbar-links_container{
    display: flex;
    flex-direction: row;
    margin-left: 2rem;
    padding-right: 10px;
}

.app__navbar-sign{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 45px;
    
}

.app__navbar-sign img{
    width: 100%;
    height: 20px;
}


.app__navbar-links_container p,
 .app__navbar-menu_container{
    color: #000;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    text-transform: capitalize;
    justify-content: space-between;
    margin: 0 5px;
    cursor: pointer;
    float: right;
    padding: 0 8px;
 }

 .app__navbar-links_container a{
    color: #000;
    text-decoration: none;
 }

 .app__navbar-sign button{
    width: 137px;
    height: 38px;
    outline: none;
    border: 1px solid #7a7a7a;
    border-radius: 75px;
    color: #515151;
    font-size: 20px;
    font-weight: 500;
    font-family: var(--font-family);
    background: white;
    cursor: pointer;
 }

 .app__navbar-sign button:active{
    background: #f3f3f3;
 }

 .app__navbar-menu{
    margin-left: 1rem;
    display: none;
    position: relative;
    z-index: 1000;
 }

 .app__navbar-menu svg{
    cursor: pointer;
 }

 .app__navbar-menu_container{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 212px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0,0,0,0.2);
    background: #fff;
 }


 .app__navbar-menu_container p{
    margin: 1rem 0;
 }

 .app__navbar-menu_container-links-sign{
    display: none;
 }

 .app__navbar-menu_container-links a{
    color: #000;
    text-decoration: none;
 }

 .app__navbar-menu_container-links a:hover{
    background: red;
 }


 @media screen and (max-width:1050px) {
    .app__navbar-links_container{
        display: none;
    }
    .app__navbar-menu{
        display: flex;

    }
    .subnav-content1, .subnav-content2{
      left:0;
    }
    .subnav-content1{
      width: 100%;
    }
 }


 @media screen and (max-width:700px) {
    .app__navbar{
        padding: 2rem 4rem;
    }
    .subnav-content1, .subnav-content2{
      left:0;
    }
    .subnav-content1{
      width: 100%;
    }
 }


 @media screen and (max-width:550px) {
    .app__navbar-links_container{
        display: none;
    }

    .app__navbar{
        padding: 1rem ;
    }

    .app__navbar-sign{
        display: none;
    }

    .app__navbar-menu_container{
        top: 20px;
    }

    .app__navbar-menu_container-links-sign{
        display: block;
    }

    .subnav-content1, .subnav-content2{
      left:0;
    }
    .subnav-content1{
      width: 100%;
    }
 }


 .app__navbar-links_container p:hover{
    background: rgb(241, 166, 166);
 }

 
