.team-member {
    border: 1px solid #000;
    padding: 1rem;
    margin-bottom: 1rem;
    color: #000;
    width: 90%;
    font-family: var(--font-family);
  }

  @media (max-width: 768px) {
    .team-member {
      padding: 0.5rem;
    }
  }