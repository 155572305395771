/* ContactUs.css */
.contact-margin{
  margin: 20px;
}


.app__contact-form-heading{
    font-family: var(--font-family);
    font-size: 30px;
  }


  
  .contact-us {
      width: 30em;
      margin: 0 auto;
      padding: 20px 1rem;
      border: 1px solid #ccc;
      border-radius: 5px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }

    .contact-image{
        width: 100%;
    }


    .contact-heading h1{
      font-family: var(--font-family);
      text-align: center;
    }
    
    .form-group {
      margin-bottom: 15px;
    }
    
    label {
      display: block;
      font-weight: bold;
      color: #1b0202;
      font-family: var(--font-family);
    }
    
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    textarea {
      display: flex;
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 3px;
    }
    
    input[type="submit"] {
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 3px;
      padding: 10px 20px;
      cursor: pointer;
      font-size: 16px;
      font-family: var(--font-family);
    }
    
    input[type="submit"]:hover {
      background-color: #0056b3;
    }
    


    .contact_split-left{
      margin:4px, 4px;
      width: 500px;
      overflow-x: hidden;
      overflow-y: auto;
      text-align:justify;
      align-items: center;
      padding: 20px;
      height: 60vh;
    }
  

    .contact_split{
      margin-top: 100px;
      margin-left: 6em;
  }
  
  .contact_split-screen {
      display: flex;
      height: auto;
      width: 100%;
      margin: 60px 30px 0;
    }
    
     .contact_split-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;
      height: 60vh;
    }
    
    .contact_split-left {
      background-color: gray;
      color: #fff;
      font-family: var(--font-family);
    }
    
    .conatact_split-right {
      background-color: #f1f1f1;
    }
  
  
    .contact_split-left p{
      text-align: justify;
      font-family: var(--font-family);
    }
  
    @media (max-width: 768px) {
      .contact_split{
        margin: 0 0 0 10px;
      }

      .contact_split-screen {
        flex-direction: column;
        margin: 0;
      }
      
      .contact_split-left{
        height: 40vh;
        margin: 0;
      }

      .contact_split-left, .contact_split-right {
        flex: initial;
        width: auto;
        height: auto;
      }

      .contact-us{
        width: auto;
      }

      .contact_split-left h1{
        font-size: 17px;
      }

      .contact_split-left p{
        font-size: 13px;
        text-align: left;
      }
      .contact-heading h1{
        font-size: 22px;
      }
    }


   /* Responsive adjustments for smaller screens */
@media (max-width: 1080px) {
  .contact_us {
    max-width: 100%; /* Set the form width to full width */
    margin-left: 0;
  }

  .contact-heading {
    margin: 20px 0; /* Reduce margin for better spacing */
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    width: 100%; /* Set input elements to full width */
  }

  .contact_split-screen {
    flex-direction: column;
    margin: 0;
    align-items: center;
  }

  .contact_split-left{
    height: 25vh;
  }

  .contact_split-right{
    flex: 0;
  }

  .contact_split{
    margin-top: 0px;
  }
}



