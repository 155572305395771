.split-screen {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: auto;
}

.left-section,
.right-section {
  flex: 1;
}

.left-section img,
.right-section img {
  max-width: 100%;
  height: 50vh;
}

.center-section {
  flex: 1;
  text-align: center;
  padding: 20px;
  font-family: var(--font-family);
}

/* Media query for screens with a max width of 768px */
@media (max-width: 1045px) {
    .left-section img,
    .right-section img {
      max-width: 100%;
      height: 30vh;
    }
}

@media (max-width: 768px) {
  .split-screen {
    flex-direction: column;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
  }

  .left-section,
  .right-section {
    flex: initial;
  }
}
