.Block-container{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    box-shadow: 0 7px 10px rgba(0,0,0,0.2);
    background: rgba(99, 97, 97, 0.925);
    border-radius: 20px;
    
    
}

.Block-heading{
    font-family: var(--font-family);
    width: 90%;
    padding: 10px;
}

.Block-heading h1{
    padding: 10px 20px 0;
    font-size: 31px;
    color: rgba(243, 23, 23, 0.925);
    font-weight: 600;
    font-family: 'Times New Roman', Times, serif;
    line-height: 25px;
    text-transform: capitalize;
}

.Block-subheading{
    font-size: 18px;
    color: rgba(243, 23, 23, 0.925);
    font-weight: 600;
    font-family: 'Arial Narrow', sans-serif;
    text-transform: capitalize;
    padding: 0 20px;
    margin: 0;
    line-height: 10px;
}


.Block-content{
    padding: 10px 20px 10px 20px;
    color: #fff;
    text-align: justify;
    font-family: var(--font-family);
}



@media screen and (max-width: 768px) {
    .Block-container {
      flex-direction: column; /* Stack elements vertically */
      margin-top: 20px;
    }
  
    .Block-heading h1 {
      font-size: 20px; /* Reduce font size */
    }
  
    .Block-subheading {
      font-size: 16px; /* Reduce font size */
    }

    .Block-content{
      font-size: 13px;
    }
  }


  
@media screen and (max-width: 450px){
  .Block-heading h1 {
    font-size: 17px; /* Reduce font size */
  }

  .Block-subheading {
    font-size: 13px; /* Reduce font size */
  }

  .Block-content{
    font-size: 11px;
  }
}