/* Add a container to limit the width */
.container-with-dots {
  max-width: 1200px;
  margin: 0 auto;
}

.multi-carousel {
  box-shadow: 0 4px 8px 0 rgba(133, 118, 118, 0.2);
  margin: 0 auto; /* Center the carousel */
}

.multi-carousel img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.multi-container {
  padding: 0 16px;
}

.subTitle1 {
  font-family: var(--font-family);
  cursor: pointer;
}

.Title1 {
  font-size: 18px;
  font-family: var(--font-family);
}

.multi-container a {
  color: gray;
  text-decoration: none;
  font-family: var(--font-family);
}

/* Adjust the width of the carousel for smaller screens */
@media screen and (max-width: 991px) {
  .multi-carousel {
    width: 80%; /* Adjust the width as needed */
  }
  .multi-carousel img{
    height: 30vw;
  }
}

@media screen and (max-width: 768px){
  .Title1{
    font-size: 16px;
  }

  .subTitle1{
    font-size: 13px;
  }
}

@media screen and (max-width: 450px) {
  .multi-carousel {
    width: 80%; /* Adjust the width as needed */
  }
  .multi-carousel img{
    height: 100vw;
  }
}
