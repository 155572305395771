/* Global styles (unchanged) */

.company {
  overflow: hidden;
  margin: 20px;
}

/* Company Container */
.company_container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 30px 20px 0 20px;
  background: linear-gradient(176deg, #A09F9F 0%, #9597A2 14.48%, #484CA7 100%, #FFFFFF 10%);
  text-align: center;
}

.company_container-heading {
  font-family: var(--font-family);
  margin: 10px 20px 0 20px;
}

.company_container-heading h1 {
  width: 100%;
  font-size: 40px;
  font-weight: bold;
  color: red;
  font-family: var(--font-family);
}

.company_container-heading p {
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  line-height: 22px;
  padding-bottom: 10px;
  font-family: var(--font-family);
}

/* Company Block */
.company_block-container,
.company_block-container-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #003396;
  margin: 50px 0 0 0;
  width: 100%;
  height: auto;
}

.company_block-container-left{
  border-radius: 123.3px 0px 0px 123.3px;
}

.company_block-container{
  border-radius: 0px 123.3px 123.3px 0px;
}

.company_block-heading,
.company_block-heading-left {
  font-family: var(--font-family);
  padding: 10px;

}

.company_block-heading img, .company_block-heading-left img{
  width: 20%;
  height: 20%;
  border-radius: 50%;
  padding: 10px 10px 10px;
  background: #fff;
  margin: 10px 0;
}

.company_block-heading-left img{
  float: left;
  padding-left: 10px;
}

.company_block-heading img{
  float: right;
  padding-right: 10px;
}

.company_block-heading h1, .company_block-heading-left h1 {
  color: #FFF500;
  font-style: italic;
  line-height: 31.47px;
  font-weight: 700;
  font-size: 26px;
  padding-top: 10px;
  font-family: var(--font-family);
}

.company_block-heading p, .company_block-heading-left p {
  color: #fff;
  font-weight: 400;
  font-size: 20px;
  line-height: 27.84px;
  text-align: justify;
  padding: 10px;
  font-family: var(--font-family);
}

/* Company Container1 */
.company_container1 {
  margin-top: 100px;
  padding: 30px 20px 0 20px;
  background: linear-gradient(176deg, #A09F9F 0%, #9597A2 14.48%, #484CA7 100%, #FFFFFF 10%);
}

.company_container-heading1 {
  font-family: var(--font-family);
  margin: 10px 20px 0 20px;
}

.company_container-heading1 p {
  width: 100%;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  padding-bottom: 30px;
  font-family: var(--font-family);
}


/* Media Query for small screens (max-width: 768px) */
@media screen and (max-width: 768px) {
  .company_container {
    flex-direction: column;
    text-align: center;
    padding: 30px 10px;
    height: auto;
  }

  .company_container-heading h1 {
    font-size: 25px;
  }

  .company_container-heading p {
    font-size: 13px;
    text-align: center;
    width: 90%;
    margin: 0 auto;
  }

  .company_block-container-left,
  .company_block-container {
    width: 100%;
    border-radius: 25px;
  }

  .company_block-heading p {
    margin-right: 0;
  }

  .company_container1 {
    height: auto;
  }

  .company_container-heading1 p {
    width: 90%;
    font-size: 13px;
    margin: 0 auto;
  }
}
