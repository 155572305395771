.about-margin{
  margin: 20px;
}
  
  /* Header Styles */
  .header {
    position: relative;
  }
  
  .header img {
    width: 100%;
    height: auto;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 2rem;
    text-align: center;
    font-size: 20px;
    font-family: var(--font-family);
  }


  
  /* Admin and Team Section Styles */
  .admin-section, .team-section {
    margin: 2rem 1rem 0 1rem;
  }
  
  .admin-section h2{
    text-align: center;
    font-family: var(--font-family);
  }
  
  /* Responsive Styles (adjust as needed) */
  @media (max-width: 768px) {
    .overlay {
      padding: 1rem;
    }
    .overlay h1{
      font-size: 17px;
    } 

    .admin-section h2, .about-Title{
      font-size: 17px;
    }
  
    .overlay p, .about-subTitle{
      font-size: 13px;
    }


  }

  /* Three columns side by side */


/* Display the columns below each other instead of side by side on small screens */


/* Add some shadows to create a card effect */
.about-card {
  width: 50%;
  display: block;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 4px 8px 0 rgba(133, 118, 118, 0.2);
}

/* Some left and right padding inside the container */
.about-container2 {
  padding: 0 16px;

}

/* Clear floats */
.about-container2::after, .about-row1::after {
  content: "";
  clear: both;
  display: table;
}

.about-subTitle {
  color: grey;
  font-family: var(--font-family);
  text-align: center;
}


.about-Title{
  color: var(--color-text);
  font-family: var(--font-family);
}


.about-card img{
  width: 100%;
  height: 50vw;
  object-fit: cover;
}

.about-row1{
  margin: auto;
}


.about-container2 h2{
  font-size: 18px;
  font-family: var(--font-family);
}
  