.industries-margin{
  margin: 20px;
}

.industries{
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    flex-direction: column;

}



.industries_heading h1{ 
    text-align: center;
    margin: 40px;
    color: #000;
    font-family: var(--font-family);

}

.industries-container-left{
    display: flex;
    align-items: center;
}

.industries_heading-left{
    font-family: var(--font-family);
    padding-right: 20px;
    box-shadow: 8px 8px 8px 8px rgba(133, 118, 118, 0.2);
}

.industries_heading-left img{
    width: 200px;
    height: 30%;
    float: left;
    margin-left: 30px;
    padding: 0px 20px 0 20px;
}


.industries_heading-left h1{
    margin-top: 10px;
    padding-top: 10px;
    background: var(--gradient-text);
    background-clip: text;
    --webkit-background-clop: text;
    -webkit-text-fill-color: transparent;
    font-style: italic;
    line-height: 31.47px;
    font-weight: 700;
    font-size: 26px;
    margin-left: 9em;
    padding-left: 20px;
    font-family: var(--font-family);
  }

  .industries_heading-left p{
    color: #000;
    font-weight: 400;
    font-size: 23px;
    line-height: 27.84px;
    text-align: justify;
    margin-left: 10em;
    padding: 10px 20px 15px 20px;
    font-family: var(--font-family);
  }
 

 
  .industries_column1 {
    float: left;
    width: 29%; /* Adjust width for larger screens */
    margin: 0 1% 16px 3%; /* Adjust margins */
  }
  
  /* Display the columns below each other instead of side by side on small screens */
  @media screen and (max-width: 786px) {
    .industries_column1 {
      width: 100%;
      margin: 0 0 16px 0; /* Adjust margins */
    }
    .industries_heading2 h1{
      font-size: 25px;
    }

    .industries_content{
      font-size: 19px;
    }

  }
  
  /* Rest of your existing styles... */
  
  .industries_heading2 {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  
  .industries_heading2 h1 {
    color: #000;
    font-family: var(--font-family);
  }

  
  .industries_content {
    width: 100%;
    color: #000;
    font-weight: 400;
    font-size: 23px;
    line-height: 27.84px;
    padding: 30px 20px 15px 0px;
    overflow: hidden;
    font-family: var(--font-family);
    text-align: center;
  }

  .industries_card{
    padding-left: 20px; 
  }
  
  .industries_card img {
    max-width: 100%; /* Make sure images are responsive */
    height: auto;
    padding: 20px 20px 0 0px;
  }
  

  
  .industries_Bigcontainer {
    box-shadow: 8px 4px 8px 4px rgba(133, 118, 118, 0.2);
    overflow: hidden; /* Clear the floated columns */
    margin-top: 10px;
  }
  


  
@media (max-width: 786px) {
    .industries-container-left {
      flex-direction: column; /* Stack content vertically on smaller screens */
      align-items: center;
    }
  
    .industries_heading-left h1 {
      margin-left: 9em;
      padding-left: 30px;
      font-size: 22px;
    }
  
    .industries_heading-left p {
      margin: 0;
      font-size: 19px;
    }

    .industries_heading2 {
      font-size: 22px;
    }
  
    .industries_content{
      font-size: 19px;
    }

  }


  @media (max-width: 450px) {
    .industries-container-left {
      flex-direction: column; /* Stack content vertically on smaller screens */
      align-items: center;
    }
  
    .industries_heading-left h1 {
      margin-left: 0;
      padding-left: 0;
      font-size: 20px;
      text-align: center;
    }
  
    .industries_heading-left p {
      margin: 0;
      font-size: 16px;
    }
    .industries_heading-left img{
      width: 100%;
      height: auto;
      float: 0;
      margin-left: 0;
  }

  .industries_heading2 {
    font-size: 20px;
  }

  .industries_content{
    font-size: 16px;
  }
  }
  
  
  
  
  
  
  


  

  
  
  
  
  