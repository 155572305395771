
.Stsol-margin{
  margin: 20px;
}

.Stsol_container{
    display: flex;
    align-items: center;
    padding: 30px 20px 0 20px;
}

.Stsol_container-heading{
    font-family: var(--font-family);
}


.Stsol_container-heading h1{
    font-size: 30px;
    font-weight: 600;
    font-family: var(--font-family);
}

.Stsol_container-heading p{
    font-size: 18px;
    font-weight: 400;
    text-align: justify;
    font-family: var(--font-family);
}




/* Three columns side by side */
.Stsol_column1 {
    float: left;
    width: 40%;
    padding: 0 8px;
    margin: 0 30px 16px 60px;
  }
  
  /* Display the columns below each other instead of side by side on small screens */
  @media screen and (max-width: 650px) {
    .Stsol_column1 {
      width: 100%;
      display: block;
    }
  }
  
  /* Add some shadows to create a card effect */
  
  
  /* Some left and right padding inside the container */
  .Stsol_container2 {
    padding: 0 16px;
  }
  
  /* Clear floats */
  .Stsol_container2::after, .Stsol_row1::after {
    content: "";
    clear: both;
    display: table;
  }
  
  

  .Stsol_Title{
    font-family: var(--font-family);
    padding-top: 20px;
  }

  .Stsol_content{
    font-family: var(--font-family);
    text-align: justify;
    font-weight: 400;
    line-height: 20px;
  }


  .Stsol_card img{
    max-width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 25px;
  }

  .Stsol_row1{
    margin-top: 50px;
  }



  .Stsol_container2 h2{
    font-size: 18px;
    font-weight: 600;
    font-family: var(--font-family);
  }


  .Stsol_block-margin{
    margin-top: 100px;
  }

  .Stsol_horicard-margin{
    margin-top: 100px;
  }



  @media screen and (max-width: 650px) {
    .Stsol_container {
      flex-direction: column; /* Stack elements vertically */
      text-align: center;
    }
  
    .Stsol_container-heading {
      margin: 10px 20px 0 20px;
    }
  
    .Stsol_container-heading h1 {
      font-size: 24px; /* Reduce font size */
    }
  
    .Stsol_container-heading p {
      font-size: 16px; /* Reduce font size */
      text-align: center;
    }
  
    .Stsol_column1 {
      width: 100%; /* Display as a single column */
      margin: 0 10px; /* Reduce margin */
    }
  }