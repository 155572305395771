/* Three columns side by side */
.column1 {
    float: left;
    width: 25%;
    margin-bottom: 16px;
    padding: 0 8px;
  }
  
  /* Display the columns below each other instead of side by side on small screens */
  @media screen and (max-width: 650px) {
    .column1 {
      width: 100%;
      display: block;
    }
  }
  
  /* Add some shadows to create a card effect */
  .card {
    box-shadow: 0 4px 8px 0 rgba(133, 118, 118, 0.2);
  }
  
  /* Some left and right padding inside the container */
  .container2 {
    padding: 0 16px;
  }
  
  /* Clear floats */
  .container2::after, .row1::after {
    content: "";
    clear: both;
    display: table;
  }
  
  

  .Title{
    color: var(--color-text);
    font-family: var(--font-family);
    font-size: 18px;
  }


  .card img{
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }

  .row1{
    margin-top: 50px;
  }



  @media screen and (max-width: 768px) {
    .Title{
      font-size: 16px;
    }
  }