
.Ftmanage-margin{
  margin: 20px;
}


.Ftmanage-margin{
  margin: 20px;
}

.Ftmanage_container{
    display: flex;
    align-items: center;
    padding: 30px 20px 0 20px;
}

.Ftmanage_container-heading{
    font-family: var(--font-family);
}

.Ftmanage_row2{
  display: flex;
  justify-content: center;
  align-items: center;
}

.Ftmanage_container-heading h1{
    font-size: 30px;
    font-weight: 600;
    font-family: var(--font-family);
}

.Ftmanage_container-heading p{
    font-size: 18px;
    font-weight: 400;
    text-align: justify;
    font-family: var(--font-family);
}




/* Three columns side by side */
.Ftmanage_column1 {
    float: left;
    width: 40%;
    padding: 0 8px;
    margin: 0 30px 16px 60px;
  }
  
  /* Display the columns below each other instead of side by side on small screens */
  @media screen and (max-width: 650px) {
    .Ftmanage_column1 {
      width: 100%;
      display: block;
    }
  }
  
  /* Add some shadows to create a card effect */
  
  
  /* Some left and right padding inside the container */
  .Ftmanage_container2 {
    padding: 0 16px;
  }
  
  /* Clear floats */
  .Ftmanage_container2::after, .Ftmanage_row1::after {
    content: "";
    clear: both;
    display: table;
  }
  
  

  .Ftmanage_Title{
    font-family: var(--font-family);
    padding-top: 20px;
  }

  .Ftmanage_content{
    font-family: var(--font-family);
    text-align: justify;
    font-weight: 400;
    line-height: 20px;
  }


  .Ftmanage_card img{
    max-width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 25px;
  }

  .Ftmanage_row1{
    margin-top: 50px;
  }



  .Ftmanage_container2 h2{
    font-size: 18px;
    font-weight: 600;
    font-family: var(--font-family);
  }


  .Ftmanage_block-margin{
    margin-top: 100px;
  }

  .Ftmanage_horicard-margin{
    margin-top: 100px;
  }



  @media screen and (max-width: 650px) {
    .Ftmanage_container {
      flex-direction: column; /* Stack elements vertically */
      text-align: center;
    }
  
    .Ftmanage_container-heading {
      margin: 10px 20px 0 20px;
    }
  
    .Ftmanage_container-heading h1 {
      font-size: 24px; /* Reduce font size */
    }
  
    .Ftmanage_container-heading p {
      font-size: 16px; /* Reduce font size */
      text-align: center;
    }
  
    .Ftmanage_column1 {
      width: 100%; /* Display as a single column */
      margin: 0 10px; /* Reduce margin */
    }
  }





.Ftmanage_Block-container{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    box-shadow: 0 7px 10px rgba(0,0,0,0.2);
    background: rgba(78, 46, 46, 0.925);
    border-radius: 20px;
    
    
}

.Ftmanage_Block-heading{
    font-family: var(--font-family);
    width: 90%;
    padding: 10px;
}



.Ftmanage_Block-content{
    padding: 0 20px 0 20px;
    color: #fff;
    text-align: justify;
    font-family: var(--font-family);
}


@media screen and (max-width: 768px) {
  .Ftmanage_container {
    flex-direction: column; /* Stack elements vertically */
    text-align: center;
    padding: 30px 20px; /* Reduce padding */
  }

  .Ftmanage_container-heading h1 {
    font-size: 24px; /* Reduce font size */
    line-height: 25px;
  }

  .Ftmanage_subheading {
    font-size: 20px; /* Reduce font size */
  }

  .Ftmanage_content {
    font-size: 16px; /* Reduce font size */
  }

  .Ftmanage-container,
  .Ftmanage-container1,
  .Ftmanage-container2 {
    margin-left: 0;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .Ftmanage_Block-container {
    flex-direction: column; /* Stack elements vertically */
    text-align: center;
    margin: 10px;
  }

  .Ftmanage_Block-heading {
    width: 100%;
  }
}

@media screen and (max-width: 939px) {
  .Ftmanage_row2{
    display: inline-block;
  }

}
