.services-container {
    background-image: url('https://shorturl.at/gmuGH'); /* Set your main background image here */
    background-size: cover;
    background-position: center;
    text-align: center;
    padding: 20px;
    color: #fff; /* Text color for better readability on the background */
  }
  
  .services-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    
  }
  
  .center-service {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .service {
    width: 300px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5); /* Background for the service block with some transparency */
    border: 2px solid #fff; /* Border around the service block */
    border-radius: 10px; /* Rounded corners */
    color: #fff; /* Text color for better readability */
    margin: 5px;
  }
  
  .service-content {
    text-align: center;
    font-family: var(--font-family);
  }
  
  .service h3 {
    font-size: 18px;
    margin: 0;
    font-family: var(--font-family);
  }
  
  .service p {
    font-size: 14px;
    margin: 0;
    font-family: var(--font-family);
  }


  @media (min-width: 768px) {
    .services-row {
      flex-direction: row;
    }
  
    .service {
      width: auto;
    }
  }