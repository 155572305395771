@import url('https://fonts.googleapis.com/css2?family=PT+Serif&family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
  --gradient-text: linear-gradient(90deg, #A7928A 0%, #F33A3A 50%, #A08787 100%);
  --font-family: 'PT Serif', serif;
}

body::-webkit-scrollbar{
  
    display: none;
  
  
  /* Hide scrollbar for IE, Edge and Firefox */
  
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  
}